/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keyword, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const keywords = keyword
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: keywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name" : "TheSoftCode LLC",
          "url": "https://thesoftcode.com",
          "name": "TheSoftCode Web Development Company",
          "legalName" : "TheSoftCode LLC",
          "foundingDate": "2020",
           "address": {
              "@type": "PostalAddress",
              "streetAddress": "3 Germay Dr, Unit 4 #2104",
              "addressLocality": "Wilmington, Delaware",
              "addressRegion": "DE",
              "postalCode": "19804",
              "addressCountry": "USA"
            },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-302-273-8202",
             "email": "support@thesoftcode.com",
            "contactType": "Customer Support"
          },
          "aggregateRating" : {
            "@type" : "AggregateRating",
            "bestRating" : "5",
            "ratingCount" : "2"
          },
           "sameAs": [ 
              "https://www.facebook.com/thesoftcoded/",
              "https://twitter.com/TheSoftCode",
              "https://www.instagram.com/thesoftcode/",
              "https://www.linkedin.com/company/thesoftcode"
            ]
        }
      `}
        </script>
      </Helmet>
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keyword: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
