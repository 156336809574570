import * as React from "react"
// FaFacebookSquare,
// FaTwitterSquare,
// FaInstagramSquare,
// FaLinkedin,
import {
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa"
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi"
import { Container, Row, Col } from "react-bootstrap"
const footer = () => {
  return (
    <footer className="footer bg-dark py-1">
      <br />
      <Container fluid className="text-center text-white text-decoration-none">
        <Row className="position-sticky mb-5 fs-3">
          <Col>
          <span>
            <a
              href="https://www.facebook.com/thesoftcoded/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiFacebook className="ms-2 me-1 fb-icon" />
            </a>
            <a
              href="https://twitter.com/TheSoftCode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiTwitter className="ms-2 me-1 tweet-icon" />
            </a>
            <a
              href="https://www.instagram.com/thesoftcode/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiInstagram className="ms-2 me-1 gplus-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/thesoftcode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiLinkedin className="ms-2 me-1 link-icon" />
            </a></span>
          </Col>
        </Row>
        <Row className="row-cols-auto mb-3 mt-2 text-center">
          <Col sm className="w-100">
            <FaPhoneAlt className="mb-1" />
            &nbsp;
            <a
              className="text-decoration-none text-reset"
              href="tel:+13022738202"
            >
              +1(302)&nbsp;273-8202
            </a>
          </Col>
          <Col sm className="w-100">
            <FaEnvelope className="mb-1" />
            &nbsp;
            <a
              href="mailto:info@thesoftcode.com"
              className="text-decoration-none text-reset"
              title="Email Us"
            >
              info@thesoftcode.com
            </a>
          </Col>
          <Col sm className="w-100">
            <a
              href="https://thesoftcode.com"
              className=" text-decoration-none text-reset"
              title="TheSoftCode LLC Trademark"
            >
              <small>©2022 TheSoftCode LLC. All rights reserved.</small>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default footer
