import React, { useState } from "react"
import { Link } from "gatsby"
import { bubble as Menu } from "react-burger-menu"
import { StaticImage } from "gatsby-plugin-image"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { MdAssignment, MdSend, MdClose } from "react-icons/md"
// import { GiClick } from "react-icons/gi"
import axios from "axios"

const Header = () => {
  // const { location } = props;
  const [show, setShow] = useState(false)

  // const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // const [validated, setValidated] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const quoteView = ["showForm", "showLoading", "showSuccess", "showError"]
  const [viewQuoteShow, setViewQuoteShow] = useState(quoteView[0])

  const submitQuote = async () => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      msg: values.msg,
    }

    try {
      await axios.post(
        "https://thesoftcode.com/static/api/index.php",
        payload,
        {
          headers: {
            "content-type": "text/plain",
          },
        }
      )
      // .then((response) => {
      //   console.log(response.status);
      // })
        
    } catch (err) {
      // Handle Error Here
      // console.log('something went wrong :(', e);
      setViewQuoteShow(quoteView[3])
    }
  }

  const initialState = {
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    msg: "",
  }
  const [values, setValues] = useState(initialState)

  //this method handles the each form field changing and updates the relevant
  //state value for that input
  const handleChange = event => {
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }
  const clearState = () => {
    setShow(false)
    setViewQuoteShow(quoteView[0])
  }
  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    setLoading(true)
    setViewQuoteShow(quoteView[1])
    submitQuote()
    setLoading(false)
    setViewQuoteShow(quoteView[2])
    setValues(initialState)
  }

  return (
    <>
      <div className="navbar-brand mt-1 ms-1">
        <Link id="TSCLogoLink" to="/">
          <StaticImage
            id="logorotate"
            src="../images/TSCLogo.svg"
            layout="constrained"
            width={40}
            alt="TheSoftCode Logo"
            className="img-fluid giveshadow mt-1"
          />
          <StaticImage
            id="logotext"
            src="../images/TSC_LOGO_TEXT.png"
            layout="constrained"
            width={110}
            alt="TheSoftCode Logo Text"
            className="img-fluid giveshadowlogotext"
          />
        </Link>
      </div>
      <div>
        <Button
          variant="info"
          className="rounded-pill quotebutton shadow border-light text-white"
          onClick={handleShow}
        >
          CONSULT&nbsp;<MdAssignment className="mb-1 shadow" />
        </Button>

        <Modal
          show={show}
          onHide={clearState}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <MdAssignment className="mb-1 text-primary" />
              &nbsp;FREE CONSULTATION!
            </Modal.Title>
          </Modal.Header>

          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {viewQuoteShow === "showForm" && (
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First Name</Form.Label>
                    <FormControl
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder="Enter First Name"
                      required
                    />
                    <FormControl.Feedback type="invalid">
                      Please Enter valid First Name.
                    </FormControl.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last Name</Form.Label>
                    <FormControl
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder="Enter Last Name"
                      required
                    />
                    <FormControl.Feedback type="invalid">
                      Please Enter Valid Last Name.
                    </FormControl.Feedback>
                  </Form.Group>
                </Row>
              )}
              {viewQuoteShow === "showForm" && (
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      placeholder="Enter Company Name"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Company Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
              {viewQuoteShow === "showForm" && (
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom04">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      placeholder="Enter Phone Number"
                      minLength={10}
                      maxLength={10}
                      min={0}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Phone Number.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom05">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Enter Email"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Email Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
              {viewQuoteShow === "showForm" && (
                <Row>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="msg"
                      value={values.msg}
                      onChange={handleChange}
                      placeholder="Enter Comments"
                      rows={3}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Some Comments.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
              {viewQuoteShow === "showLoading" && (
                <Row className="text-center">
                  <Col>Submitting your quote, please wait...</Col>
                </Row>
              )}
              {viewQuoteShow === "showSuccess" && (
                <Row className="text-center">
                  <Col>
                    Thank you for submitting quote request, we will get back to
                    you shortly.
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={clearState}>
                <MdClose className="mb-1" />
                &nbsp;Close
              </Button>
              {viewQuoteShow === "showForm" && (
                <Button variant="primary" type="submit" disabled={isLoading}>
                  <MdSend className="mb-1" />
                  &nbsp;Send
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <Menu right width={200} disableAutoFocus className="text-center">
        <Link
          id="home"
          className="menu-item"
          activeClassName="activelnk disabled"
          to="/"
        >
          HOME
        </Link>
        <br />
        <Link
          id="webdesign"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/Web-Design"
        >
          WEB DESIGN
        </Link>
        <br />
        <Link
          id="ecommerce"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/ecommerce"
        >
          ECOMMERCE
        </Link>
        <br />
        <Link
          id="mobileapp"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/Mobile-app"
        >
          MOBILE APP
        </Link>
        <br />
        <Link
          id="seo"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/seo"
        >
          SEO
        </Link>
        <br />
        <Link
          id="uiux"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/uiux"
        >
          UI/UX
        </Link>
        <br />
        <Link
          id="branding"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/branding"
        >
          BRANDING
        </Link>
        <br />
        <Link
          id="portfolio"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/portfolio"
        >
          PORTFOLIO
        </Link>
        <br />
        <Link
          id="blog"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/blog"
        >
          BLOGS
        </Link>
        <br />
        <Link
          id="about"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/About"
        >
          ABOUT
        </Link>
        <br />
        <Link
          id="connect"
          className="menu-item mt-3"
          activeClassName="activelnk disabled"
          to="/connect"
        >
          CONNECT
        </Link>
        <br />
        <br />
        <Button
          variant="info"
          className="rounded-pill shadow text-white"
          onClick={handleShow}
        >
          CONSULT
        </Button>
      </Menu>
    </>
  )
}

export default Header
